import React, {useEffect, useRef} from "react";
import Container from "../../components/Container/Container";
import HSNominationForm from "../../components/HSNominationForm/HSNominationForm";
import Page from "../../components/Page/Page";
import {getStorage, ref, getDownloadURL} from "firebase/storage"
import {NominationForm} from "../../components/HSNominationForm/NominationForm";
import extraMileAwardIcon from "../../images/extraMileAwardIcon.png"
import innovationAwardIcon from "../../images/innovationAwardIcon.png"
import customerObsessionIcon from "../../images/customerObsessionAwardIcon.png"
import highPerformanceBehaviourIcon from "../../images/highPerformanceBehaviourAwardIcon.png"

const Nomination = () => {
    const imgRef = useRef(null);

    useEffect(() => {
        const storage = getStorage();
        const imageRef = ref(storage, 'page_banner.png');

        getDownloadURL(imageRef)
            .then((url) => {
                // Set the 'src' attribute of the imgRef.current to the obtained URL
                if (imgRef.current) {
                    imgRef.current.setAttribute('src', url);
                }
            })
            .catch((error) => {
                // Handle any errors
            });
    }, []);

    return (
        <Page>
            <Container>
                <div className="mb-4">
                    <img ref={imgRef} alt="banner" id={'page_banner'}/>
                </div>
                <NominationHeading/>
                <NominationForm/>
            </Container>
        </Page>
    );
};

const NominationHeading = () => {
    return (
        <>
            <h2>Award Categories</h2>
            <div className={'sf__cata__wrapper'}>
                <div className={'sf__cata__container'}>
                    <div className={'sf__cata__header'}>
                        <h3>
                            <img src={extraMileAwardIcon} alt={'extra mile award icon'}/>
                            Extra Mile Award
                        </h3>
                    </div>
                    <p>
                        A person who through their outstanding efforts or talents, has consistently provided excellent service to their internal customers and / or is a committed, reliable, collaborative, enthusiastic and flexible team player. This award is for internally facing award.
                    </p>
                </div>
                <div className={'sf__cata__container'}>
                    <div className={'sf__cata__header'}>
                        <h3>
                            <img src={innovationAwardIcon} alt={'innovaiton award icon'}/>
                            Innovation Award
                        </h3>
                    </div>
                    <p>
                        A person who through innovative thinking has made noticeable improvements to the area in which they work by introducing new ideas that either improve customer service, products, reduce waste, cut costs or create new opportunities or alternatively, has submitted an innovative idea for consideration.
                    </p>
                </div>
                <div className={'sf__cata__container'}>
                    <div className={'sf__cata__header'}>
                        <h3>
                            <img src={customerObsessionIcon} alt={'customer obsession icon'}/>
                            Customer Obsession Award
                        </h3>
                    </div>
                    <p>
                        A person who through their outstanding efforts or talents, has consistently provided excellent service to their external customers.
                    </p>
                </div>
            </div>
            <div className={'sf__best__player__container'}>
                <h3>
                    <img src={highPerformanceBehaviourIcon} alt={'high performance behaviour award icon'}/>
                    High Performance Behaviour Award
                    <img src={highPerformanceBehaviourIcon} alt={'high performance behaviour award icon'}/>
                </h3>
                <br/>
                <div className={'sf__teamplayer__table'}>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Speak up and out</div>
                        <div className={'sf__table__content__container__content'}>This means speaking up respectfully when you have good ideas, need to challenge the status quo, face unexpected challenges or see people not living our values.</div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Seek clarity and provide clarity</div>
                        <div className={'sf__table__content__container__content'}>Clarity happens when you ask questions to make sure you know what is expected of you, and when you clearly communicate your expectations of others. </div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Collaborate for mutual success</div>
                        <div className={'sf__table__content__container__content'}>Collaboration happens when you understand Altron’s products, services and solutions no matter your role, and when you break down silos to share knowledge, skills and resources willingly across the group. </div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>See excellence and recognise excellence</div>
                        <div className={'sf__table__content__container__content'}>Show appreciation by saying “thank you”. Acknowledge and recognise achievements big and small, in a fair and consistent way.</div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>I’m accountable and you’re accountable</div>
                        <div className={'sf__table__content__container__content'}>Accept responsibility for your own actions and the quality of your work. Remind others when they are not executing with excellence, no matter their role.  Speak up when things don’t go according to pan and always do what you said you would do.</div>
                    </div>
                </div>
            </div>
            <div className={'sf__nomination__heading'}>
                <h3>🏆 Tell us all about the person you are nominating 🏆</h3>
                <p>Please note that your award nomination is completely anonymous.</p>
            </div>
        </>
    )
}

export default Nomination;
