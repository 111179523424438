import './NominationForm.css'
import {memo, useCallback, useEffect, useState} from "react";
import staffData from '../../data/staff_q1_2024.json'
import {useAppState} from "../../state";

const ShortTextForm = (({type, placeholder = '', label, req, fieldName, fieldValue, updateNominationObj}) => {
    return (
        <div className={'sf__form__container__flex sf__name__input'}>
            <label className={`sf__input__label ${req ? 'sf__input__label__req' : ''} `}
                   htmlFor={fieldName}>{label}</label>
            <input value={fieldValue} className={'sf__input__form'} placeholder={placeholder} name={fieldName}
                   required={req}
                   type={type} onChange={(event) => updateNominationObj(fieldName, event.target.value)}/>
        </div>
    )
})

const DropdownForm = (({label, req, fieldName, updateNominationObj, choices}) => {
    return (
        <div className={'sf__form__container__flex'}>
            <label className={`sf__input__label ${req ? 'sf__input__label__req' : ''}`}
                   htmlFor={fieldName}>{label}</label>
            <select defaultValue={''} className={'sf__input__form'} name={'awardCata'}
                    onChange={(event) => updateNominationObj(fieldName, event.target.value)}>
                <option value={''} disabled>Select an Option</option>
                {choices.map((value, index) => {
                    return (
                        <option value={value.replace(/ /g, '')} key={index}>{value}</option>
                    )
                })}
            </select>
        </div>
    )
})

const LongTextForm = (({placeholder = '', label, req, fieldName, fieldValue, updateNominationObj}) => {
    return (
        <div className={'sf__form__container__flex'}>
            <label className={`sf__input__label  ${req ? 'sf__input__label__req' : ''}`}
                   htmlFor={fieldName}>{label}</label>
            <textarea value={fieldValue} className={'sf__input__form'} name={'innovationFurther'}
                      placeholder={placeholder}
                      onChange={(event) => updateNominationObj(fieldName, event.target.value)}
                      required={req}
            />
        </div>
    )
})

export const NominationForm = () => {
    const {addNewNomination, user, logError} = useAppState();
    const [displaySuggestion, setDisplaySuggestion] = useState(false)
    const [suggestedEmails, setSuggestedEmails] = useState([])
    const [nominationObj, setNominationObj] = useState({
        email: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        department: '',
        awardCategory: '',
        nominationDetails: {},
    })
    const [submitted, setSubmitted] = useState(false)

    const updateNominationObj = ((field, value) => {
        setNominationObj(prevState => ({
            ...prevState,
            [field]: value,
        }));
    });

    const updateNominationDetails = ((detailField, value) => {
        setNominationObj(prevState => ({
            ...prevState,
            nominationDetails: {
                ...prevState.nominationDetails,
                [detailField]: value,
            }
        }))
    })

    const resetNominationDetails = (() => {
        setNominationObj(prevState => ({
            ...prevState,
            nominationDetails: {}
        }))
    })

    const resetAndUpdateNominationDetails = ((detailField, value) => {
        updateNominationObj(detailField, value)
        resetNominationDetails()
    })

    //Used to get the field from the JSON file.
    const emailValue = 'Email - Work'

    useEffect(() => {
        if (nominationObj['email'].length < 3) {
            setDisplaySuggestion(false)
            return
        }

        const newSuggestions = []
        staffData.forEach((data) => {
            const emailString = data[emailValue].toLowerCase()

            if (emailString.includes(nominationObj.email.toLowerCase())) {
                newSuggestions.push(emailString)
            }
        })

        if (newSuggestions !== []) {
            setSuggestedEmails(newSuggestions)
            setDisplaySuggestion(true)
        }
    }, [nominationObj['email']]);

    const autoFillData = (searchTerm) => {
        staffData.forEach(data => {
            if (data[emailValue].toLowerCase() === searchTerm.toLowerCase()) {
                updateNominationObj('email', data[emailValue])
                updateNominationObj('firstName', data['First Name'])
                updateNominationObj('lastName', data['Last Name'])
                updateNominationObj('jobTitle', data['Business Title'])
                updateNominationObj('department', data['Department'])
                setDisplaySuggestion(false)
            }
        })
    }

    const submitFormData = (event) => {
        const validity = document.getElementById('sf__fintechform').checkValidity()

        if (!validity) return

        event.preventDefault()
        setSubmitted(true)

        addNewNomination(nominationObj, user)
            .then(() => {
            })
            .catch(async (e) => {
                await logError(e, user, 'Nomination Form', event.data.data);
                console.log(e);
            })
    }

    const GenerateFollowupContent = (data) => {
        switch (data) {
            case 'InnovationAward':
                return (
                    <>
                        <LongTextForm
                            label={'What Problem Does This Innovation Solve For Its Users And Or How Has This Innovation Made A Positive Impact'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'problemSolveInnovation'}
                            fieldValue={nominationObj.nominationDetails['What Problem Does This Innovation Solve For Its Users And Or How Has This Innovation Made A Positive Impact']}
                        />

                        <DropdownForm
                            label={'Is This Innovation A New Idea Or Is It An Improvement On Something That Already Exists'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'InnovationType'}
                            choices={['New Idea', 'Already Exists']}
                        />

                        <ShortTextForm
                            fieldValue={nominationObj.nominationDetails['Who Will Use This Innovation']}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'InnovationUser'}
                            req={true}
                            label={'Who Will Use This Innovation'}
                            type={'text'}
                        />

                        <LongTextForm
                            label={'Please Provide Details Of The Innovation'}
                            fieldName={'innovationDetails'}
                            updateNominationObj={updateNominationDetails}
                            req={true}
                            fieldValue={nominationObj.nominationDetails['Innovation Details']}
                        />

                        <LongTextForm
                            label={'Additional Comments'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'additionalComments'}
                            fieldValue={nominationObj.nominationDetails['Additional Comments']}
                        />
                    </>
                )
            case 'CustomerObsessionAward':
                return (
                    <>
                        <LongTextForm
                            label={'Who does this person serve externally?'}
                            req={true}
                            fieldValue={nominationObj.nominationDetails['Who Does This Person Serve externally']}
                            fieldName={'serveExternally'}
                            updateNominationObj={updateNominationDetails}
                        />

                        <LongTextForm
                            fieldName={'customerNeeds'}
                            fieldValue={nominationObj.nominationDetails['How did they meet their external customers needs in a way that went above and beyond expectations / beyond the normal requirements of the job?']}
                            label={'How did they meet their external customers’ needs in a way that went above and beyond expectations / beyond the normal requirements of the job?'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                        />

                        <LongTextForm
                            fieldName={'serviceImpact'}
                            fieldValue={nominationObj.nominationDetails['What impact did this service have on the external customer? Please provide specific examples.']}
                            label={'What impact did this service have on the external customer? Please provide specific examples.'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                        />

                        <LongTextForm
                            fieldName={'serviceImpact'}
                            fieldValue={nominationObj.nominationDetails['What impact did this service have on the external customer? Please provide specific examples.']}
                            label={'What impact did this service have on the external customer? Please provide specific examples.'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                        />

                        <LongTextForm
                            label={'Additional Comments'}
                            req={false}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'additionalComments'}
                            fieldValue={nominationObj.nominationDetails['Additional Comments']}
                        />
                    </>
                )
            case 'ExtraMileAward':
                return (
                    <>
                        <LongTextForm
                            label={'How did the nominee meet their internal customers needs in a way that went above and beyond'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'extraMileAbove'}
                            placeholder={''}
                            fieldValue={nominationObj.nominationDetails['How did the nominee meet their internal customers needs in a way that went above and beyond']}
                        />

                        <ShortTextForm
                            placeholder={''}
                            fieldValue={nominationObj.nominationDetails['Who Does This Person Serve Internally']}
                            fieldName={'extraMileServeInternally'}
                            updateNominationObj={updateNominationDetails}
                            req={true}
                            label={'Who Does This Person Serve Internally'}
                            type={'text'}
                        />

                        <LongTextForm
                            label={'Extra mile award further comments'}
                            fieldValue={nominationObj.nominationDetails['Extra mile award further comments']}
                            placeholder={'Any extra details'}
                            req={false}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'extraMileComments'}
                        />
                    </>
                )
            case 'HighPerformanceBehaviourAward':
                return (
                    <>
                        <LongTextForm
                            label={'Elaborate On How The Person Makes A Significant Contribution To The Team And Is An Excellent Role Model'}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'significantContribution'}
                            req={true}
                            fieldValue={nominationObj.nominationDetails['Elaborate On How The Person Makes A Significant Contribution To The Team And Is An Excellent Role Model']}
                            placeholder={''}
                        />

                        <DropdownForm
                            label={'What behaviour is best suitable towards the nominated individual?'}
                            req={true}
                            updateNominationObj={updateNominationDetails}
                            fieldName={'behaviourType'}
                            choices={['Speak Up And Out', 'Seek Clarity And Provide Clarity', 'Collaborate For Mutual Success', 'See Excellence And Recognise Excellence', 'I\'m Accountable And You\'re Accountable']}
                        />
                    </>
                )
            default:
                return (
                    <>
                    </>
                )
        }
    }

    const FormSubmitted = () => {
        return (
            <>
                <h2>Woohoo! We've got your Nomination! 🎉</h2>
            </>
        )
    }

    if(submitted)
        return <FormSubmitted/>
    else {
        return (
            <form id={'sf__fintechform'}>
                <div className={'sf__form__container__flex sf__form__container__email'}>
                    <label className={'sf__input__label sf__input__label__req'} htmlFor={'email'}>Email of person you
                        are
                        nominating</label>
                    <input required value={nominationObj['email']} className={'sf__input__form'} placeholder={'Email'}
                           name={'email'} type={'email'}
                           onChange={(e) => updateNominationObj('email', e.target.value)}/>
                    <div className={`sf__email__suggestion sf__email__suggestion__${displaySuggestion}`}>
                        {suggestedEmails.map((data, index) => {
                            return (
                                <div key={index} className={'sf__suggestion__button'}
                                     onClick={() => autoFillData(data)}>
                                    <p>{data}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={'sf__form__name__flex'}>
                    <ShortTextForm
                        placeholder={'First Name'}
                        type={'text'}
                        fieldName={'firstName'}
                        fieldValue={nominationObj.firstName}
                        updateNominationObj={updateNominationObj}
                        label={'First name of person you are nominating'}
                        req={true}
                    />
                    <ShortTextForm
                        placeholder={'Last Name'}
                        type={'text'}
                        fieldName={'lastName'}
                        label={'Last name of person you are nominating'}
                        req={true}
                        updateNominationObj={updateNominationObj}
                        fieldValue={nominationObj.lastName}
                    />
                </div>
                <ShortTextForm
                    placeholder={'Job Title'}
                    updateNominationObj={updateNominationObj}
                    fieldValue={nominationObj.jobTitle}
                    type={'text'}
                    fieldName={'jobTitle'}
                    label={'Job Title'}
                    req={true}
                />
                <DropdownForm
                    label={'Award Category'}
                    req={true}
                    updateNominationObj={resetAndUpdateNominationDetails}
                    fieldName={'awardCategory'}
                    choices={['Extra Mile Award', 'Innovation Award', 'Customer Obsession Award', 'High Performance Behaviour Award']}
                />

                {GenerateFollowupContent(nominationObj['awardCategory'])}
                <button onClick={(event) => submitFormData(event)} disabled={!nominationObj['awardCategory']}>Submit
                </button>
            </form>
        )
    }
}
