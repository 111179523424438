import React from "react";
import { useParams, Link } from "react-router-dom";

const CategoriesMenu = () => {
  const { department } = useParams();

  return (
    <div>
      <h3 className="text-2xl font-light text-center">
        Click below to access the different categories:
      </h3>
      <div className="flex md:flex-row flex-col items-center justify-between w-3/4 mx-auto font-bold my-8 mb-24">
          <Link className={'text-center'} to={`/nominations/${department}/extra-mile-award`}>Extra Mile Award</Link>
          <Link className={'text-center'}  to={`/nominations/${department}/innovation-award`}>Innovation Award</Link>
          <Link className={'text-center'}  to={`/nominations/${department}/customer-obsession-award`}>Customer Obsession Award</Link>
          <Link className={'text-center'}  to={`/nominations/${department}/high-performance-behaviour-award`}>High Performance Behaviour Award</Link>
        {/* <Link to={`/nominations/${department}/top-performer`}>
          Top Performer
        </Link> */}
      </div>
    </div>
  );
};

export default CategoriesMenu;
