import React, {useEffect, useRef} from "react";
import Container from "../../components/Container/Container";
import Page from "../../components/Page/Page";
import { useParams } from "react-router-dom";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import ExtraMileAwardIcon from '../../images/extraMileAwardIcon.png'
import InnovationAwardIcon from '../../images/innovationAwardIcon.png'
import CustomerObsessionAwardIcon from '../../images/customerObsessionAwardIcon.png'
import HighPerformanceBehaviourAward from '../../images/highPerformanceBehaviourAwardIcon.png'

const Categories = () => {
  const imgRef = useRef(null);

  useEffect(() => {
    const storage = getStorage();
    const imageRef = ref(storage, 'page_banner.png');

    getDownloadURL(imageRef)
        .then((url) => {
          // Set the 'src' attribute of the imgRef.current to the obtained URL
          if (imgRef.current) {
            imgRef.current.setAttribute('src', url);
          }
        })
        .catch((error) => {
          // Handle any errors
        });
  }, []);

  const { department, quarter } = useParams();
  const url = quarter
    ? `/nominations/${quarter}/${department}`
    : `/nominations/${department}`;
  return (
    <Page>
      <Container>
        <div className="mb-24">
          <img ref={imgRef} alt="banner" />
        </div>
        <h2 className="text-center text-4xl font-semibold mb-4">
          Pick a category
        </h2>
        <p className="text-center mb-16">
          Click on a category below to see the nominations for each & vote for
          your favourite.
        </p>
        <div className="grid md:grid-cols-4 px-8 md:px-0 gap-4 mb-24">
          <CategoryCard
              title="Extra Mile Award"
              image={ExtraMileAwardIcon}
              link={`${url}/extra-mile-award`}
          />
          <CategoryCard
              title="Innovation Award"
              image={InnovationAwardIcon}
              link={`${url}/innovation-award`}
          />
          <CategoryCard
              title="Customer Obsession Award"
              image={CustomerObsessionAwardIcon}
              link={`${url}/customer-obsession-award`}
          />
          <CategoryCard
            title="High Performance Behaviour Award"
            image={HighPerformanceBehaviourAward}
            link={`${url}/high-performance-behaviour-award`}
          />
        </div>
      </Container>
    </Page>
  );
};

export default Categories;
