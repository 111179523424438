import React from 'react';
import Card from './Card';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';

function NominationListing() {
  const { nominations, user, getVotesByUser } = useAppState();

  const [filteredNominations, setFilteredNominations] = React.useState([]);
  const [votingEnabled, setVotingEnabled] = React.useState(true);

  const { department, category } = useParams();

  const [userVotes, setUserVotes] = React.useState([]);

  const testVotingEnabled = async () => {
    const votes = await getVotesByUser(user);
    let voteCount = 0;
    let votedNominationIds = [];
    if (votes) {
      votes.forEach((vote) => {
        if (vote.category.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() === category) {
          voteCount++;
          votedNominationIds.push(vote.nominationId);
        }
      });
      setVotingEnabled(voteCount < 2);
      setUserVotes(votedNominationIds);
      console.log('votedNominationIds: ', votedNominationIds);
    }
  };

  React.useEffect(() => {

    const filteredData = nominations.filter((item) => {
      console.log( item.department
          .toLowerCase()
          .replaceAll(' ', ''))
      return (
        item.awardCategory.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() === category &&
        item.department
          .toLowerCase()
            .replaceAll(' ', '') === department &&
        item.approved
      );
    });
    setFilteredNominations(filteredData);

    // setVotingEnabled(isVotingEnabled());
    testVotingEnabled();
    //eslint-disable-next-line
  }, [nominations, category, department]);

  // React.useEffect(() => {
  //   console.log('Voting enabled: ', votingEnabled);
  // }, [votingEnabled]);

  return (
    <>
      {filteredNominations.length > 0 ? (
        <ul className="card-list px-4 md:px-0 grid md:grid-cols-2 gap-8">
          {filteredNominations &&
            filteredNominations.map((card) => (
              <Card
                key={card['id']}
                {...card}
                name={`${card['firstName']} ${card['lastName']}`}
                department={card['department']}
                jobTitle={card['jobTitle']}
                id={card['id']}
                category={card['awardCategory']}
                votingEnabled={votingEnabled}
                setVotingEnabled={setVotingEnabled}
                testVotingEnabled={testVotingEnabled}
                motivation={card['nominationDetails']}
                hasVote={userVotes.includes(card.id)}
              />
            ))}
        </ul>
      ) : (
        <p className="text-center font-bold text-gray-400 mx-14">
          Sorry - there are no nominations for this award
        </p>
      )}
    </>
  );
}

export default NominationListing;
